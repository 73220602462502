.banner {
	padding: 10px 0;

	.brand {
		display: block;
		max-width: 150px;

		@media #{$mobile}{
			max-width:140px;
		}

		img {
			width: 75%;
    		height: auto;
    		margin: 20px 0;
		}

		@media #{$mobile} {
			margin: 0 auto;

			img{
				width:100%;
			}
		}
	}

	.menu-container {
		padding: 10px 0 9px;
		float: right;
		font-size:20px;
		margin:5vh 0;

		a{
			color:rgba(26, 26, 26, 0.74);
		}
		
		li{
			margin:0 10px;
		}

		.navbar-nav {
			float: none;
		}

		@media #{$tablet}{
			
			font-size:18px;
			float:none;

			li{
				margin:0;
			}
		}
	}
}