.hero-image {
	background-size: cover;
	background-position: center center;
	color: #fff;
	padding: 10vw 0;
	position: relative;

	@media #{$mobile}{
		padding:25vh 0;
	}

	* {
		position: relative;
		z-index: 2;
	}

	.hero-title {

		width:60%;

		span {
			@extend h1;
			font-size: 80px;
			line-height: 1.1em;
			color: #fff;
			font-weight:400;

			@media #{$tablet} {
				font-size: 50px;
			}

			@media #{$mobile} {
				font-size: 40px;
				text-align: center;
				display: block;
			}
		}
	}

	.hero-text {
		text-transform: uppercase;
		font-size: 24px;
		font-weight: 100;
		padding-left: 6px;

		@media #{$tablet} {
			font-size: 25px;
		}

		@media #{$mobile} {
			font-size: 20px;
			text-align: center;
		}
	}

	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.2);
	}
}

#glutenfree {
	padding:35px 0;

	.box-content {
		color:#FFF;margin:0 -20px 0 0;
		p {color: #FFF}

		img {width:110%!important;}
	}

	.leftglutten {padding:20px!important;}

	.box-content {border-left:none!important;}
		

	h3 {color:#F7062F;margin:30px 0 0 0;display:block;}
	h4 {margin:0 0 40px 0;padding:0;color:#333;}
	
	
}

#course1,#course2,#course3 {

	.container {}
}

#amatuersection {
	background:#000;color:#FFF;
	p {color:#FFF;}
	h1,h2,h3 {color:#FFF;}
	margin:40px 0 0;
}

#who-we-are,
#what-we-do,
#services,
#contact,
#courses,
#startingbusiness,
#gallery,
#courses,
#course1,#course2,#course3,#amatuersection,#glutenfree {
	padding: 60px 0;

	.box-title {



		h1,
		h2 {
			font-size: 46px;
			width:80%;
			font-weight:500;

			@media #{$tablet} {
				font-size:40px;
			}

			@media #{$mobile} {
				font-size:28px;
			}
		}
	}

	.box-content {

		

		border-left: 1px solid #aaa;
		padding: 0 0 0 20px;
		min-height: 70px;
		font-size: 16px;

		@media #{$tablet} {
			border-left: 0;
			border-top: 1px solid #aaa;
			padding: 20px 0 0 0;
		}


		p  {


			em {font-weight:normal;}

		}


	}

	.services-content{
			font-size:16px;
	}
}

.envira-gallery-wrap .envira-gallery-public.envira-gallery-css-animations .envira-gallery-item img {
	opacity: 0.8 !important;
}

#contact{
	background-color:#111;
	color:#aaa;

	h2{
		color:#aaa;
	}

	li a{
		color:#aaa;
		font-size:16px;
	}

	.fa{
		font-size:40px;
		margin:10px 0;
	}

}

.gallery{
	padding:70px 0 0;
}

.image-container.low {height:35vh;}

.image-container {
	height: 70vh;
	background-color: #eee;



	@media #{$mobile}{
		height:50vh;
	}
}

.map-container {
	position: relative;
	padding-bottom: 33vh;
	height: 0;
	overflow: auto; 
	-webkit-overflow-scrolling:touch;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}

	&:after {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 2;
		content: '';
		width: 100%;
		height: 100%;
	}
}

.image-container {
	background-size: cover;
	background-position: center center;
}

.gform_wrapper {
	margin: 0 !important;

	.gform_body {

		#input_2_5,
		#input_2_2,
		#input_2_3,
		#input_2_4{
			background-color:#111;
		}

		.gfield {
			margin: 0 0 16px !important;
		}

		input[type="text"],
		textarea {
			padding: 10px !important;
			border: 1px solid #aaa !important;
		}
	}

	.gform_footer {
		margin: 0 !important;
		padding: 0 !important;

		input[type="submit"] {
			background-color:#111;
			color:#fff;
			border:solid #aaa 1px;
			border-radius:10px;
			padding:4px 30px;

		}
	}
}

.home-page-link {

	a {
		color: #fff;
		font-size: 20px;
		background-color: $primary;
		padding: 15px 0;
		line-height: 20px;
		text-align: center;
		display: block;
		font-weight: 100;
		text-transform: uppercase;

		&:hover {
			background-color: darken($primary, 5%);
			text-decoration: none;
		}
	}
}

.university-container{

	margin-top:15px;
	
	.button-container{
		padding-top:30px;
	}

	@media #{$tablet}{
		img{
			width:75%;
			margin:0 auto;
			display:block;
		}
	}
}

.header-container {
	background: #bb0021;
	margin-bottom: 60px;

	.page-header {
		border: 0;
		margin: 0;
		padding: 0;

		h1 {
			color: #fff;
			margin: 0;
			line-height: 100px;
		}
	}
}

.blog-single-container {
	margin-bottom:  60px;

	h2 {
		font-size: 18px;
		text-transform: none;
		font-weight: 700;
		margin-top: 22px;
	}
}

.header-right {
	padding-top: 38px;
	color: rgba(26,26,26,.74);
}

.banner .menu-container{
	margin: 0;
	padding: 0;
}

.content-info {
	padding: 20px 0 40px;
}

.contact-header{
	margin:0 25px 0 0;
	padding-top: 10px;
	float: right;
	text-align: right;
}

.contact-header span{
	font-size:16px;
	font-weight:500;
}

.gallery {
	padding: 20px 0px 0px;
}
.image-container {
    height: 650px;
}

@media (max-width: 767px) {
	.banner .brand {
	    text-align: center;
	}
	.banner .brand img {
	    width: 70%;
	    height: auto;
	    margin: 0;
	}
	.hero-image .hero-title span {
	    font-size: 40px;
	    text-align: center;
	    display: block;
	}
	.hero-image .hero-title {
	    margin: 0 auto;
	    width: 100%;
	}
	.content-info .copyright {
		text-align: center;
	}

	.box-content p,
	#services .services-content,
	#contact .services-content p,
	#contact li a,
	.gform_wrapper .top_label .gfield_label {
		font-size:16px;
	}

	#who-we-are,
	#what-we-do,
	#services,
	#contact,
	.single-post-container {
		padding: 30px 0;
	}

	#who-we-are,
	#what-we-do,
	#services,
	#contact {
		padding: 30px 0;
	}
	.gform_wrapper .gform_body input[type=text], .gform_wrapper .gform_body textarea {
		padding: 2px 10px !important;
	}

	#contact {
		padding-bottom: 0px;
	}

	.gallery {
		padding: 20px 0 0;
	}
	.contact-header {
		float: none;
		text-align: center;
		margin: 0;
	}
	.header-right {
		padding-top: 10px;
		text-align: center;
	}
	.image-container {
	    height: 650px;
	}
	.mobile50 {
		margin: 0;
	}
	.banner {
	    padding: 20px 0;
	}
	.hero-image .hero-title span, h1, h2, h3, h4, h5, h6 {
		margin: 0;
	}
	.hero-image {
	    padding: 10vh 0;
	}
	.image-container {
	    height: 300px;
	}
}