.content-info {
	background-color:#111;

	.copyright {
		text-align: right;
	}

	p {
		color:#aaa;

		a {
			color:#e63c2f;
			font-weight:600;
		}
	}
}



