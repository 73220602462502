.btn-brown {
	@extend .btn-primary;
	@extend .btn;

	background-color: $primary;
	border-color: darken($primary, 20%);
	text-transform: uppercase;

	&:hover {
		background-color: darken($primary, 20%);
		border-color: darken($primary, 20%);
	}
}

button.btn.btn-primary{
    background-color: #0aa440;
    border-color: #0aa440;
    padding: 10px 20px;
    font-family: "Raleway", sans-serif;
    margin:0 auto;
    display:inline-block;
    font-size:16px;
    margin-top: 20px;

    &:hover{
        background-color:rgb(7, 116, 45);
    }

    &:focus{
        background-color:rgb(7, 116, 45);
    }

    &:active{
        background-color:rgb(7, 116, 45);
    }
}

a.btn.btn-primary{
    background-color: #0aa440;
    border-color: #0aa440;
    padding: 10px 20px;
    font-family: "Raleway", sans-serif;
    margin:0 auto;
    display:inline-block;
    font-size:16px;
    margin-top: 20px;

    &:hover{
        background-color:rgb(7, 116, 45);
    }

    &:focus{
        background-color:rgb(7, 116, 45);
    }

    &:active{
        background-color:rgb(7, 116, 45);
    }
}
