body {
	font-family: "Raleway", sans-serif;
	font-weight: 400;
	font-size: 16px;
}

h1 ,h2 ,h3 ,h4 ,h5 ,h6 {
	font-family: "Raleway", sans-serif;
	font-weight: 400;
	margin: 0 0 20px;
	padding: 0;
	line-height: 1.1em;
	text-transform: uppercase;
	color: #606060;
}

a {
	color: $primary;

	&:hover {
		color: darken($primary, 10%);
	}
}

img {
	max-width: 100%;
	height: auto;
}

.mobile-trigger {
	display: none;

	@media #{$mobile} {
		line-height: 30px;
		padding: 10px 0;
		font-size: 24px;
		color: #fff;
		background-color: $primary;
		display: block;
		text-align: center;

		&:focus,
		&:active,
		&:hover {
			color: #fff;
		}
	}
}

.single .post-thumbnail {
	margin-bottom: 40px;
}

.mobile {
	display: none;
	background-color: darken($primary, 2%);

	@media #{$mobile} {
		display: block;

		ul {
			list-style: none;
			padding: 0;

			a {
				padding: 15px;
				display: block;
				color: #fff;
			}
		}
	}	
}

.menu-container {

	@media #{$mobile} {
		display: none;
	}
}

.mobile50 {

	@media #{$mobile} {
		margin-bottom: 20px;
	}
}

.mb30 {
	margin-bottom: 30px;
}

.mb60 {
	margin-bottom: 60px;
}